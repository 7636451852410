<template>
  <div>
    <!-- downloadD7D4F945FC0CD792F87B6FDDAC310E93.swf -->
    <!-- pic024E9B01329F40A824DBDF3FFAF56270.jpg -->
    <img class="img-large rounded" src="">
    <p>
      Did you know that one of you lucky LEGO fans will get the glory of having your creation in LEGO Universe when the game first launches?
    </p>
    <p>
      It's true! When the first MMOG for LEGO fans lifts off next year, the gameworld will feature a creation chosen from the Creation Lab's special <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '909B728D-A1CC-4176-8F05-00655A65AF63'} }">Make a monument challenge</router-link>!
    </p>
    <p>
      Fans have already made more than a thousand inspiring submissions, but there's still a bit of time to get yours in! The challenge will remain open through August 28th, 2009, so build your best monument before the deadline!
    </p>
  </div>
</template>
